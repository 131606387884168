<template>
	<div id="Index">
		<a-row :gutter="20">
			<a-col :span="8" v-if="$store.state.userInfo.is_shop==1">
				<div class="zyl-total kd-index-store">
					<a-image class="kd-index-store_logo" :src="shopInfo.shop.logo" alt=""></a-image>
					<div class="kd-index-store_left">
						<div class="kd-index-store_left_name">{{shopInfo.shop.name}} <i class="el-icon-edit"></i></div>
						<div class="kd-index-store_left_phone"><i class="el-icon-phone-outline"></i> {{shopInfo.shop.mobile}}</div>
						<div class="kd-index-store_left_address"><i class="el-icon-location-outline"></i> {{shopInfo.shop.address}}</div>
					</div>
				</div>
			</a-col>
			<a-col :span="4" v-if="$store.state.userInfo.is_shop!=1">
				<div class="zyl-total">
					<div class="zyl-total_icon zyl-icon1">
						<i class="zy-licon-title el-icon-user-solid"></i>
					</div>
					<div class="zyl-total_count">
						<div class="zyl-total_count_title">总用户数(人)</div>
						<div class="zyl-total_count_value" style="color: #53e0f7;">{{statistics.account_count}}</div>
					</div>
				</div>
			</a-col>
			<a-col :span="4">
				<div class="zyl-total">
					<div class="zyl-total_icon zyl-icon2">
						<i class="zy-licon-title el-icon-shopping-bag-2"></i>
					</div>
					<div class="zyl-total_count">
						<div class="zyl-total_count_title">商品数（件）</div>
						<div class="zyl-total_count_value" style="color: #929ef5;">{{statistics.product_count}}</div>
					</div>
				</div>
			</a-col>
			<a-col :span="4">
				<div class="zyl-total">
					<div class="zyl-total_icon zyl-icon3" >
						<i class="zy-licon-title el-icon-coin"></i>
					</div>
					<div class="zyl-total_count">
						<div class="zyl-total_count_title">今日交易额（元）</div>
						<div class="zyl-total_count_value" style="color: #f75158;">￥{{statistics.order.total}}</div>
					</div>
				</div>
			</a-col>
			<a-col :span="4" v-if="$store.state.userInfo.is_shop!=1">
				<div class="zyl-total">
					<div class="zyl-total_icon zyl-icon4">
						<i class="zy-licon-title el-icon-money"></i>
					</div>
					<div class="zyl-total_count">
						<div class="zyl-total_count_title">待提现</div>
						<div class="zyl-total_count_value" style="color: #f179e2;">{{statistics.withdraw.wait}}</div>
					</div>
				</div>
			</a-col>
			
			<a-col :span="4">
				<div class="zyl-total">
					<div class="zyl-total_icon zyl-icon5">
						<i class="zy-licon-title el-icon-s-grid"></i>
					</div>
					<div class="zyl-total_count">
						<div class="zyl-total_count_title">商品待审核</div>
						<div class="zyl-total_count_value" style="color: #49b548;">{{statistics.wait_audit.wait_audit}}</div>
					</div>
				</div>
			</a-col>
			
			<a-col :span="4">
				<div class="zyl-total">
					<div class="zyl-total_icon zyl-icon6">
						<i class="zy-licon-title el-icon-ship"></i>
					</div>
					<div class="zyl-total_count">
						<div class="zyl-total_count_title">订单待发货</div>
						<div class="zyl-total_count_value" style="color: #58baff;">{{statistics.order.wait_send}}</div>
					</div>
				</div>
			</a-col>
			
		</a-row>
		
		<a-row :gutter="15">
			
			<a-col :span="8">
				<div class="grid-content bg-purple" style="height: 180px;">
					<div id="chartOrder" style="width: 100%; height: 180px;"> </div>
				</div>
			</a-col>
			<a-col :span="8">
				<div class="grid-content bg-purple" style="height: 180px;">
					<div id="chartLand" style="width: 100%; height: 180px;"> </div>
				</div>
				
			</a-col>
			<a-col :span="8">
				<div class="grid-content bg-purple" style="height: 180px;">
					<div id="chartAdopt" style="width: 100%; height: 180px;"> </div>
				</div>
				
			</a-col>
		</a-row>
		
		<!-- 商城统计 -->
		<a-row :gutter="20">
			<a-col :span="16">
				<div class="grid-content bg-purple">
					<div id="chartColumn" style="width: 100%; height: 300px;"> </div>
				</div>
			</a-col>
			<a-col :span="8">
				<div class="grid-content bg-purple">
					<div>商品销量排行</div>
					<div class="zyl-goods-total">
						<div class="zyl-goods-total_rank">排名</div>
						<div class="zyl-goods-total_goods_over">
							封面
						</div>
						<div class="zyl-goods-total_goods_name" style="color: #000000;">商品名称</div>
						<div class="zyl-goods-total_sale">销量</div>
					</div>
					<div class="zyl-goods-total" v-for="(item,index) in statistics.goods" :key="index">
						<div class="zyl-goods-total_rank" :class="['zyl-goods-total_red'+index]">{{index+1}}</div>
						<div class="zyl-goods-total_goods_over">
							<a-image style="width: 20px; height: 20px" :src="item.cover" ></a-image>
						</div>
						<div class="zyl-goods-total_goods_name">{{item.goods_name}}</div>
						<div class="zyl-goods-total_sale">{{item.true_sale}}</div>
					</div>
					
				</div>
			</a-col>
		</a-row>
	</div>
</template>

<script>
import * as echarts from 'echarts'
import walden from '@/assets/js/walden.js'
import { reactive, toRefs } from 'vue'
import store from '@/store'
import shopModel from '@/api/addons/shop'
export default {
	setup(){
		const state = reactive({
			statistics:{order:{},withdraw:{},land:{},wait_audit:{}},
			chartColumn: null,
			land:[],
			adopt:[],
			shop:[],
			chartOrder:null,
			date:[],
			shopInfo:{shop:{name:'',mobile:'',address:'',logo:''}},	//多商户店铺信息
		})

		getTotalData()

		if( store.state.userInfo.is_shop == 1){
			shopModel.getShopUserDetail(res=>{
				state.shopInfo = res
				store.dispatch('setUsername', res.mobile);
			})
		}

		function getTotalData(){
			shopModel.getShopStatistics(res=>{
				state.statistics = res
				let obj = state.statistics.transaction
				Object.keys(obj).forEach(function(key){
					state.shop.push(obj[key].shop)
					state.adopt.push(obj[key].adopt)
					state.land.push(obj[key].land)
					state.date.push(fun_date(-key))
				});
				state.date.reverse()
				drawLine();
			})
		}

		function fun_date(aa){
			var date1 = new Date()
			var date2 = new Date(date1);
			date2.setDate(date1.getDate()+aa);
			return date2.getFullYear()+"-"+(date2.getMonth()+1)+"-"+date2.getDate();
		}

		function drawLine() {
			state.chartColumn = echarts.init(document.getElementById('chartColumn'),'walden');
			state.chartColumn.setOption({
					title : {
							text: '最近七日',
							subtext: '最近七日'
						},
						tooltip : {
							trigger: 'axis'
						},
						legend: {
							data:['商城','土地','认养']
						},
						toolbox: {
							show : true,
							feature : {
								mark : {show: true},
								dataView : {show: true, readOnly: false},
								magicType : {show: true, type: ['line', 'bar']},
								restore : {show: true},
								saveAsImage : {show: true}
							}
						},
						calculable : true,
						xAxis : [
							{
								type : 'category',
								boundaryGap : false,
								data : state.date
							}
						],
						yAxis : [
							{
								type : 'value',
								axisLabel : {
									formatter: '{value} 元'
								}
							}
						],
						series : [
							{
								name:'商城',
								type:'line',
								data: state.shop,
								smooth:true,
								markPoint : {
									data : [
										{type : 'max', name: '最大值'},
										{type : 'min', name: '最小值'}
									]
								},
								markLine : {
									data : [
										{type : 'average', name: '平均值'}
									]
								}
							},
							{
								name:'土地',
								type:'line',
								smooth:true,
								data:state.land,
								markPoint : {
									data : [
										{name : '周最低', value : -2, xAxis: 1, yAxis: -1.5}
									]
								},
								markLine : {
									data : [
										{type : 'average', name : '平均值'}
									]
								}
							},{
								name:'认养',
								type:'line',
								smooth:true,
								data:state.adopt,
								markPoint : {
									data : [
										{name : '周最低', value : -2, xAxis: 1, yAxis: -1.5}
									]
								},
								markLine : {
									data : [
										{type : 'average', name : '平均值'}
									]
								}
							}
						]
			});
			
			state.chartOrder =  echarts.init(document.getElementById('chartOrder'),'walden');
			state.chartOrder.setOption({
				title : {
						text: '订单数据统计',
						subtext: '',
						x:'center'
					},
					tooltip : {
						trigger: 'item',
						formatter: "{a} <br/>{b} : {c} ({d}%)"
					},
					legend: {
						orient: 'vertical',
						left: 'left',
						data: ['待发货','待收货','已完成']
					},
					series : [
						{
							name: '访问来源',
							type: 'pie',
							radius : '55%',
							center: ['50%', '60%'],
							data:[
								{value:state.statistics.order.wait_send, name:'待发货'},
								{value:state.statistics.order.wait_take, name:'待收货'},
								{value:state.statistics.order.finish, name:'已完成'}
							],
							itemStyle: {
								emphasis: {
									shadowBlur: 10,
									shadowOffsetX: 0,
									shadowColor: 'rgba(0, 0, 0, 0.5)'
								}
							}
						}
					]
				
			})
			
			
			state.chartLand =  echarts.init(document.getElementById('chartLand'),'walden');
			state.chartLand.setOption({
				title : {
						text: '土地数据统计',
						subtext: '',
						x:'center'
					},
				tooltip: {
						trigger: 'item',
						formatter: "{a} <br/>{b}: {c} ({d}%)"
					},
					legend: {
						orient: 'vertical',
						x: 'left',
						data:['进行中','种植中']
					},
					series: [
						{
							name:'访问来源',
							type:'pie',
							radius: ['50%', '70%'],
							avoidLabelOverlap: false,
							label: {
								normal: {
									show: false,
									position: 'center'
								},
								emphasis: {
									show: true,
									textStyle: {
										fontSize: '15',
										fontWeight: 'bold'
									}
								}
							},
							labelLine: {
								normal: {
									show: false
								}
							},
							data:[
								{value:state.statistics.land.doing, name:'进行中'},
								{value:state.statistics.land.wait, name:'种植中'},
							]
						}
					]
			})
			
			state.chartAdopt=  echarts.init(document.getElementById('chartAdopt'),'walden');
			state.chartAdopt.setOption({
					title : {
						text: '认养数据统计',
						subtext: '',
						x:'center'
					},
					tooltip : {
						trigger: 'item',
						formatter: "{a} <br/>{b} : {c} ({d}%)"
					},
					legend: {
						orient: 'vertical',
						left: 'left',
						data: ['准备中','认养中','配送中']
					},
					series : [
						{
							name: '访问来源',
							type: 'pie',
							radius : '55%',
							center: ['50%', '60%'],
							data:[
								{value:state.statistics.adopt.wait, name:'准备中'},
								{value:state.statistics.adopt.doing, name:'认养中'},
								{value:state.statistics.adopt.sending, name:'配送中'}
							],
							itemStyle: {
								emphasis: {
									shadowBlur: 10,
									shadowOffsetX: 0,
									shadowColor: 'rgba(0, 0, 0, 0.5)'
								}
							}
						}
					]
				
			})
		
		}

		return{
			...toRefs(state),
			fun_date,
			getTotalData
		}
	}
}
</script>

<style lang="scss">
	.grid-content {
		background: white;
		padding: 20px;
		height: 300px;
		margin-bottom: 20px;
	}
	.kd-index-store{
		display: flex;
		
		&_logo{
			width: 100px;
			height: 100px;
			margin-right: 20px;
		}
		
		&_left{
			width: 70%;
			line-height: 30px;
			
			&_name{
				font-size: 18px;
				font-weight: bold;
			}
			&_phone , &_address{
				color: #4c4c4c;
			}
			.el-icon-edit{
				cursor: pointer;
			}
		}
	}
	
	/* 顶部全部统计 */
	.zyl-total{
		background: #FFFFFF;
		height: 150px;
		margin-bottom: 20px;
		display: flex;
		justify-content: center;
		align-items: center;
		
		&_icon{
			width: 50px;
			height: 50px;
			border-radius: 5px;
			color: #FFFFFF;
			text-align: center;
			line-height: 60px;
			.zy-licon-title{
				font-size: 28px;
			}
		}
		.zyl-icon1{
			background: #53e0f7;
			box-shadow: 1px 4px 7px #98efef;
		}
		.zyl-icon2{
			background: #929ef5;
			box-shadow: 1px 4px 7px #938ee4;
		}
		.zyl-icon3{
			background: #f75158;
			box-shadow: 1px 4px 7px #e84f72;
		}
		.zyl-icon4{
			background: #f179e2;
			box-shadow: 1px 4px 7px #f179e2;
		}
		.zyl-icon5{
			background: #49b548;
			box-shadow: 1px 4px 7px #49b548;
		}
		.zyl-icon6{
			background: #58baff;
			box-shadow: 1px 4px 7px #58baff;
		}
		&_count{
			margin-left: 10px;
			
			&_title{
				color: #868686;
				font-size: 12px;
			}
			&_value{
				font-size: 28px;
			}
		}
		
		.kd-index-store_left_address{
			font-size: 12px;
		}
		
	}

	//圆形统计图
	.chart-cycle{
		width: 100%;
		height: 200px;
		background: #FFFFFF;
		margin-bottom: 20px;
		display: flex;
	}
	.grid-content-title{
		color: #868686;
		margin-bottom: 10px;
	}

	// 商品销量统计
	.zyl-goods-total{
		width: 100%;
		height: 35px;
		line-height: 35px;
		display: flex;
		align-items: center;
		border-bottom: 1px solid #F4F4F4;
		font-size: 12px;
		&_rank{
			width: 35px;
			text-align: center;
		}
		&_goods_over{
			width: 35px;
			height: 35px;
			margin-right: 10px;
		}
		
		&_goods_name{
			width: 70%;
			height: 35px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			font-size: 12px;
			color: #868686;
		}
		
		&_sale{
			text-align: center;
			width: 20%;
		}
		
		&_red0{
			color: red;
			font-size: 18px;
			font-weight: bold;
		}
		
		&_red1{
			color: #ff5a18;
			font-size: 16px;
			font-weight: bold;
		}
		
		&_red2{
			color: #ff6100;
			font-size: 14px;
			font-weight: bold;
		}
	}
</style>
